// Never import any server-only code here, this is also used on the client side...
import type { TranslationsMap } from './types';

// English version of the translation file
export const translations: TranslationsMap = {
  emails: {
    footer: {
      kindRegards: 'With kind regards,',
      questionsOrProblems: 'Questions or problems?',
      contactUs: 'Contact us',
    },
    transportOrder: {
      confirm: 'Confirm',
      refuse: 'Refuse',
    },
    forgotPassword: {
      resetLink: 'Configure new password',
    },
    userInvite: {
      view: 'View invitation',
    },
    attachments: {
      quotation: 'Quotation {{reference}}',
    },
    customerComplaintAlert: {
      order: 'View order',
    },
  },
  truckDocumentType: {
    Insurance: 'Insurance',
    Examination: 'Examination',
  },
  trailerTypes: {
    BoxTrailer: 'Box Trailer',
    FlatBed: 'Flatbed',
    Mega: 'Mega Trailer',
    Refrigerated: 'Refrigerated',
    RoadTrain: 'Road Train',
    Tautliner: 'Tautliner',
    Joloda: 'Tautliner / Joloda',
  },
  documents: {
    note: {
      extraInformation: 'Extra information',
    },
    letter: {
      letter: 'Letter',
    },
    paymentReminder: {
      invoiceNumber: 'Invoice number',
      invoiceDate: 'Invoice date',
      dueDate: 'Due date',
      amount: 'Amount',
      expiredAmount: 'Expired amount',
      currency: 'Currency',
    },
    quotation: {
      quotation: 'Quotation',
      customerNumber: 'Customer number',
      reference: 'Reference',
      date: 'Quotation date',
      dueDate: 'Expiration date',
      from: 'From',
      to: 'To',
      volume: 'Volume',
      price: 'Price',
      generalTermsAndConditions: 'General sales conditions attached',
      questionsOrComments: 'For questions contact us via email on {{email}}',
      trailerTypes: 'Trailer types',
    },
    transportOrder: {
      trailerTypes: 'Trailer Types',
      transportOrder: 'Transport Order',
      date: 'Date',
      orderRef: 'Order Reference',
      load: 'Load',
      unload: 'Unload',
      reference: 'Reference',
      goods: 'Goods',
      description: 'Description',
      packageType: 'Pallet type',
      palletCount: 'Pallets',
      weight: 'Weight',
      LoadingMeter: 'Loadmeter',
      freightRate: 'Freight rate',
      price: 'Price',
      vat: 'VAT',
      questionsOrComments: 'Questions or comments?',
      contactFileManager: 'Feel free to contact your file manager',
      anonymousCmr: 'ANONYMOUS CMR MUST BE CREATED FOR THIS ORDER.',
      phone: 'Phone',
      email: 'E-mail',
    },
    planning: {
      planning: 'Planning',
      licensePlate: 'License plate',
      codeName: 'Codename',
    },
    orderConfirmation: {
      orderConfirmation: 'Order Confirmation ',
      dearCustomer: 'Dear customer,',
      herebyWeConfirmYourOrder: 'We hereby confirm your order for the following transport:',
      date: 'Date',
      ourReference: 'Our reference',
      yourReference: 'Your reference',
      load: 'Load',
      unload: 'Unload',
      reference: 'Reference',
      goods: 'Goods',
      description: 'Description',
      packageType: 'Pallet type',
      palletCount: 'Pallets',
      weight: 'Weight',
      LoadingMeter: 'Loadmeter',
      freightRate: 'Freight rate',
      price: 'Price',
      vat: 'VAT',
      questionsOrComments: 'Questions or comments?',
      contactFileManager: 'Feel free to contact your file manager',
      phone: 'Phone',
      email: 'E-mail',
    },
    orderCancellation: {
      orderCancellation: 'Order cancellation',
      dearCustomer: 'Dear customer,',
      orderCancelledText: 'Your order for the transport below has hereby been cancelled:',
      date: 'Date',
      ourReference: 'Our reference',
      yourReference: 'Your reference',
      load: 'Load',
      unload: 'Unload',
      reference: 'Reference',
      goods: 'Goods',
      description: 'Description',
      packageType: 'Pallet type',
      palletCount: 'Pallets',
      weight: 'Weight',
      LoadingMeter: 'Loadmeter',
      freightRate: 'Freight rate',
      price: 'Price',
      vat: 'VAT',
      questionsOrComments: 'Questions or comments?',
      contactFileManager: 'Feel free to contact your file manager',
      phone: 'Phone',
      email: 'E-mail',
    },
    invoice: {
      invoice: 'Invoice',
      customerNumber: 'Customer number',
      invoiceNumber: 'Invoice number',
      invoiceDate: 'Invoice date',
      dueDate: 'Due date',
      load: 'Load',
      unload: 'Unload',
      ourReference: 'Our reference',
      yourReference: 'Your reference',
      description: 'Description',
      quantity: 'Amount',
      unitPrice: 'Unitprice',
      total: 'Total',
      vat: 'VAT',
      paymentInformation: 'Payment Information',
      amountToPay: 'Amount to be paid',
      toBePaidBefore: 'To be paid before',
      beneficiary: 'Beneficiary',
      message: 'Message',
      generalTermsAndConditions: 'General sales conditions attached',
      questionsOrComments: 'For any questions, please contact accounting at {{email}}',
    },
    sustainabilityReport: {
      sustainabilityReport: 'Sustainability Report',
      date: 'Date',
      ourReference: 'Our Reference',
      yourReference: 'Your Reference',
      truck: 'Truck',
      distance: 'Distance',
      weight: 'Weight',
      emissions: 'Emissions',
      questionsOrComments: 'Questions or remarks?',
      contactFileManager: 'Feel free to contact your file manager',
      phone: 'Phone',
      email: 'E-mail',
    },
    globalSustainabilityReport: {
      sustainabilityReport: 'Sustainability Report',
      date: 'Date',
      startDate: 'Start date',
      endDate: 'End date',
      orderCount: 'Number of Orders',
      truck: 'Truck',
      totals: 'Total',
      distance: 'Distance',
      weight: 'Weight',
      emissions: 'CO2 emissions',
      averagePerOrder: 'Average per order',
      framework: 'For details about the emissions calculation, please check the sustainability policy on our website.',
      questionsOrComments: 'Questions or comments?',
      contactFileManager: 'Feel free to contact your file manager',
      phone: 'Tel.',
      email: 'Email',
    },
    selfBill: {
      title: 'Self billing invoice',
      cashDiscount: 'Cash discount 2,5%',
      taxableAmount: 'Taxable amount',
      totalWithDiscount: 'Total with cash discount',
      totalWithoutDiscount: 'Total without cash discount',
      totalTitleWithDiscount: 'Cash payment in 7 days (2.5%)',
      totalTitleWithoutDiscount: 'Payment within 60 days',
      or: 'OR',
    },
    creditNote: {
      creditNote: 'Credit note',
      customerNumber: 'Customer number',
      creditNoteNumber: 'Credit note number',
      date: 'Date',
      dueDate: 'Due date',
      load: 'Load',
      unload: 'Unload',
      ourReference: 'Our reference',
      yourReference: 'Your reference',
      description: 'Description',
      quantity: 'Amount',
      unitPrice: 'Unitprice',
      total: 'Total',
      vat: 'VAT',
      paymentInformation: 'Payment Information',
      amountToPay: 'Amount to be paid',
      toBePaidBefore: 'To be paid before',
      payer: 'Payer',
      message: 'Message',
      generalTermsAndConditions: 'General sales conditions attached',
      questionsOrComments: 'For any questions, please contact accounting at {{email}}',
    },
    totals: {
      totalExclVat: 'Total excl. VAT',
      totalVat: 'Total VAT',
      totalInclVat: 'Total incl. VAT',
      vatReverseCharged: 'VAT Reverse Charge, article 44 VAT Directive',
    },
  },
  pages: {
    confirmOrderStates: {
      headingRefused: 'Order has been declined',
      headingCancelled: 'Order has been cancelled',
      headingConfirmed: 'Order has been confirmed',
      headingUnknownStatus: 'Order has already been executed or status unknown',
    },
    confirmOrderPlanningStates: {
      headingRefused: 'Planning has been declined',
      headingCancelled: 'Planning has been cancelled',
      headingConfirmed: 'Planning has been confirmed',
      headingUnknownStatus: 'Planning has already been executed or status unknown',
    },
    confirmOrder: {
      heading: 'Confirm transport order {{order.orderNumber}}',
      orderConfirmed: 'Order confirmed',
      orderConfirmationError: 'Could not confirm order: {{error}}',
      confirmButton: `Confirm transport order`,
    },
    confirmPlanning: {
      heading: 'Confirm planning {{planningDate}}',
      planningConfirmed: 'Planning confirmed',
      confirmButton: `Confirm planning`,
    },
    refuseOrder: {
      heading: 'Refuse transport order {{order.orderNumber}}',
      orderRefused: 'Order declined',
      orderRefusalError: 'Could not refuse order: {{error}}',
      refuseButton: `Refuse transport order`,
    },
    refusePlanning: {
      heading: 'Refuse planning {{planningDate}}',
      planningRefused: 'Planning declined',
      refuseButton: `Refuse planning`,
    },
    orderSummary: {
      loadAndUnloadInformation: 'Load and unload information',
      load: 'Load',
      unload: 'Unload',
      freightRate: 'Freight rate',
      total: 'Total',
    },
  },
  customerPortal: {
    requestAccount: {
      title: 'Request an account',
      success: 'Request has been submitted, we will contact you for further steps.',
      failed: 'Could not request account: {{error}}',
      name: 'Name',
      email: 'Email',
      companyName: 'Company Name',
      companyNumber: 'Company Number',
      request: 'Request',
      dontHaveAnAccount: "Don't have an account yet?",
    },
    invite: {
      title: 'Invited to Customer Portal',
      details:
        'You have been invited to the TransDirect customer portal, create your account by entering your password.',
      accepted: 'Invitation accepted, you will be redirected to the homepage shortly.',
      acceptError: 'Could not accept invitation: {{error}}',
      acceptButtonText: 'Accept Invitation',
      password: 'Password',
      repeatPassword: 'Repeat Password',
    },
    home: {
      customerPortal: 'Customer portal',
      title: 'Customer portal',
      hello: 'Hello,',
      welcome: 'Welcome to the customer portal.',
      manageOrders: 'Manage all your transport orders.',
      viewInvoices: 'View and pay invoices easily.',
      createQuotes: 'Create and track quotes.',
      viewLocations: 'View all transport locations.',
    },
    locations: {
      locations: 'Locations',
      name: 'Name',
      street: 'Street',
      number: 'Number',
      countryCode: 'Country Code',
      postalCode: 'Postal Code',
      city: 'City',
      country: 'Country',
      language: 'Language',
      notes: 'Notes',
      createLocation: 'Create Location',
      newLocation: 'New Location',
      locationCreated: 'Location created',
      couldNotCreateLocation: 'Could not create location: {{error}}',
      findLocation: 'Find a location...',
      address: 'Address',
      locationNotFound: 'Location not found',
      couldNotLoadLocation: 'Could not load location: {{error}}',
    },
    order: {
      duplicate: 'Duplicate',
      overview: 'Overview',
    },
    orders: {
      orders: 'Orders',
      placeOrder: 'Place an order',
      invoiceRef: 'Invoice reference',
      orderRef: 'Order reference',
      loadLocation: 'Load location',
      unloadLocation: 'Unload location',
      loadDate: 'Load date',
      loadTime: 'Load time',
      unloadDate: 'Unload date',
      unloadTime: 'Unload time',
      invoiceTotal: 'Invoice total',
      status: 'Status',
      invoiceStatus: 'Invoice status',
      hasCMR: 'Has CMR',
      creationDate: 'Creation date',
      executionDate: 'Execution date',
      fileManager: 'File manager',
      cancellationReason: 'Cancellation reason',
      stops: 'Stops',
      deleteStop: 'Delete stop',
      purchases: 'Purchases',
      total: 'Total',
      trailerTypes: 'Trailer types',
      extraNotes: 'Extra notes',
      noContent: 'No content',
      packaging: 'Packaging',
      coli: 'Coli',
      content: 'Content',
      addContent: 'Add content',
      deleteContent: 'Delete content',
      noStops: 'No stops',
      noStopsError: 'Add at least one loading and unloading stop',
      reference: 'Reference',
      complaints: 'Complaints',
      fileComplaint: 'File a complaint',
      fileComplaintDescription: 'Complete this form to file a complaint',
      send: 'Send',
      reason: 'Reason',
      details: 'Details',
      complaintFiled: 'Complaint filed',
      couldNotFileComplaint: 'Could not file complaint: {{error}}',
      placeAnOrder: 'Place an order',
      assignment: 'Assignment',
      addStop: 'Add stop',
      location: 'Location',
      date: 'Date',
      start: 'Start',
      stop: 'Stop',
      weight: 'Weight (kg)',
      volume: 'Volume (m3)',
      loadMeter: 'Loadmeter',
      stopsRequired: 'At least 1 stop is required',
      contentRequired: 'At least 1 content item is required',
      truck: 'Truck',
      distance: 'Distance',
      emissions: 'Emissions',
      sustainability: 'Sustainability',
      attachments: 'Attachments',
      uploadAttachments: 'Upload attachments',
      attachmentDeleted: 'Attachment deleted',
      couldNotDeleteAttachment: 'Could not delete attachment: {{error}}',
      uploadedAttachment: 'Attachment {{document}} uploaded',
      uploadFailed: 'Could not upload attachment {{document}}: {{error}}',
    },
    quotes: {
      quotes: 'Quotes',
      quote: 'Quote',
      quoteReference: 'Quote reference',
      quoteDate: 'Quote date',
      expirationDate: 'Expiration date',
      total: 'Total',
      search: 'Search for a quote...',
      quotation: 'Quote {{reference}}',
      notFound: 'Quote not found',
      departurePlace: 'Departure place',
      destination: 'Destination',
      distance: 'Distance',
      price: 'Price',
      type: 'Type',
      requests: 'Requested quotes',
      request: 'Request a quote',
      requestTitle: 'Quote request {{reference}}',
      notes: 'Notes',
      lines: 'Quote lines',
      addLine: 'Add line',
      noLines: 'No quote lines',
      requestSuccess: 'Quote requested',
      requestFailed: 'Could not request quote: {{error}}',
      requestedAt: 'Requested at',
      quotationRequests: 'Quotation requests',
      hasQuote: 'Has quote',
      generalInformation: 'General information',
      removeLine: 'Remove line',
    },
    quoteLine: {
      from: 'From',
      to: 'To',
    },
    invoices: {
      invoices: 'Invoices',
      invoiceNumber: 'Invoice number',
      invoiceDate: 'Invoice date',
      dueDate: 'Due date',
      total: 'Total',
      search: 'Search for an invoice...',
      notFound: 'Invoice not found',
      invoice: 'Invoice {{invoiceNumber}}',
      structuredComment: 'Structured comment',
      reference: 'Reference',
      sentAt: 'Sent at',
      load: 'Load',
      unload: 'Unload',
      description: 'Description',
      amount: 'Amount',
      unitPrice: 'Unit price',
      vat: 'VAT',
      extraInformation: 'Extra information: {{extraInformation}}',
      orderTotal: 'Order total: €{{total}}',
      totalExclVat: 'Total excl VAT:',
      totalVat: 'Total VAT:',
      totalInclVat: 'Total incl VAT:',
      generalInformation: 'General information',
    },
    creditNotes: {
      creditNotes: 'Credit Notes',
      creditNoteNumber: 'Credit Note Number',
      creditNoteDate: 'Credit Note Date',
      dueDate: 'Due Date',
      total: 'Total',
      search: 'Search for a credit note...',
      notFound: 'Credit Note not found',
      creditNote: 'Credit Note {{creditNoteNumber}}',
      structuredComment: 'Structured Comment',
      reference: 'Reference',
      sentAt: 'Sent on',
      load: 'Load',
      unload: 'Unload',
      description: 'Description',
      amount: 'Amount',
      unitPrice: 'Unit Price',
      vat: 'VAT',
      extraInformation: 'Extra information: {{extraInformation}}',
      orderTotal: 'Order total: €{{total}}',
      totalExclVat: 'Total excl. VAT:',
      totalVat: 'Total VAT:',
      totalInclVat: 'Total incl. VAT:',
      generalInformation: 'General Information',
    },
    businessInformation: {
      businessInformation: 'Business Information',
      updateBusinessInformation: 'Update Business Information',
      name: 'Name',
      vatNumber: 'VAT Number',
      businessNumber: 'Business Number',
      saveChanges: 'Save Changes',
      informationChanged: 'Information has been updated',
      informationNotChanged: 'Could not update information: {{error}}',
      requestChanges: 'To update company name, vat number or business number, please contact us',
    },
    ourInformation: {
      ourInformation: 'Our Information',
      name: 'Name',
      vatNumber: 'VAT Number',
      businessNumber: 'Business Number',
      address: 'Address',
      contact: 'Contact',
      phoneNumber: 'Phone Number',
      emailTransport: 'Transport Email',
      emailAccounting: 'Accounting Email',
      emailGeneral: 'General Email',
    },
    contacts: {
      contacts: 'Contacts',
      name: 'Name',
      email: 'Email',
      phone: 'Phone',
      function: 'Function',
      language: 'Language',
      notifications: 'Contact suitable for',
      newContact: 'New contact',
      contactCreated: 'Contact created',
      couldNotCreateContact: 'Could not create contact: {{error}}',
      createContact: 'Create contact',
      notFound: 'Contact not found',
      updatedContact: 'Contact updated',
      couldNotUpdateContact: 'Could not update contact: {{error}}',
      updateContact: 'Update contact',
      delete: 'Delete',
      deleteContact: 'Delete contact',
      deleteContactConfirmation: 'Are you sure you want to delete contact {{name}}?',
      deleteContactError: 'Could not delete contact: {{error}}',
      deleteContactSuccess: 'Contact deleted',
    },
    users: {
      users: 'Users',
      inviteUser: 'Invite User',
      invitedUsers: 'Invited Users',
      noInvitedUsers: 'No invited users',
      removeInvite: 'Remove Invitation',
      confirmRemoveInvite: 'Are you sure you want to remove the invitation for {{email}}?',
      inviteRemoved: 'Invitation removed',
      couldNotRemoveInvite: 'Could not remove invitation: {{error}}',
      noUsersFound: 'No users found',
      removeUser: 'Remove User',
      confirmRemoveUser: 'Are you sure you want to remove the user {{name}}?',
      userRemoved: 'User removed',
      couldNotRemoveUser: 'Could not remove user: {{error}}',
      active: 'Active',
      inactive: 'Inactive',
      userInvited: 'User invited',
      couldNotInviteUser: 'Could not invite user: {{error}}',
      name: 'Name',
      email: 'Email',
      isActive: 'Is active?',
    },
    myProfile: {
      myProfile: 'My Profile',
      logout: 'Logout',
      name: 'Name',
      role: 'Role',
      email: 'Email',
      language: 'Language',
      languageChanged: 'Language updated',
      languageNotChanged: 'Could not update language: {{error}}',
      joinedAt: 'Joined at',
      nameChanged: 'Name has been updated',
      nameNotChanged: 'Could not update name: {{error}}',
    },
    cmrReminder: {
      cmrReminder: 'CMR Reminder',
      sendReminder: 'Send',
      confirmText: 'Are you sure you want to send a CMR reminder?',
      confirmation: 'CMR Reminder sent',
      error: 'Could not send CMR reminder: {{error}}',
    },
    sustainability: {
      sustainability: 'Sustainability',
      policy: 'Sustainability Policy',
      viewOverview: 'View sustainability overview',
      orderCount: 'Number of orders',
      totalDistance: 'Total distance',
      totalWeight: 'Total weight',
      totalEmissions: 'Total CO2 emissions',
      averageDistance: 'Average distance',
      averageWeight: 'Average weight',
      averageEmissions: 'Average CO2 emissions',
      total: 'Total',
      avgPerOrder: 'Average per order',
    },
    loading: 'Loading...',
  },
  orderStatus: {
    Draft: 'Draft',
    Created: 'Created',
    Placed: 'Placed',
    Confirmed: 'Confirmed',
    Refused: 'Refused',
    Executed: 'Executed',
    Cancelled: 'Cancelled',
  },
  orderSaleStatus: {
    Created: 'Created',
    Credited: 'Credited',
    Incomplete: 'Incomplete',
    Invoiced: 'Invoiced',
  },
  customerContactNotifications: {
    invoice: 'Invoice',
    orderConfirmation: 'Order Confirmation',
    cmr: 'CMR',
    quotation: 'Quotation',
    dieselSurcharge: 'Diesel Surcharge',
    paymentReminder: 'Payment Reminder',
  },
  errors: {
    required: 'Required',
    invalidEmail: 'Invalid email',
    dateShouldBeInTheFuture: 'Date should be in the future',
    startTimeShouldBeBeforeEndTime: 'Start time should fall before end time',
    passwordsDontMatch: 'Password and repeat password do not match.',
    passwordTooShort: 'Password is too short.',
  },
  orderComplaintType: {
    Aggression: 'Physical/Verbal aggression',
    DamagedGoods: 'Damaged goods',
    IncorrectDelivery: 'Incorrect delivery',
    IncorrectInvoice: 'Incorrect invoice',
    LateDelivery: 'Late delivery',
    Other: 'Other',
  },
  auth: {
    forgotPassword: {
      title: 'Forgot Password',
      requestNewPassword: 'Request New Password',
      instructions:
        'Enter your email, if the user exists in the system, you will soon receive an email to set a new password.',
      requestFailed: 'Could not request a new password',
      instructionsSent: 'Instructions to set a new password have been sent to your email address.',
    },
    configNewPassword: {
      title: 'Set New Password',
      newPassword: 'New Password',
      repeatNewPassword: 'Repeat New Password',
      confirmButtonText: 'Set New Password',
      passwordDontMatch: 'Password and repeat password must match',
      tokenNotFound: 'Token not found',
      invalidToken: 'Token is no longer valid',
      configFailed: 'Could not set password: {{error}}',
    },
    login: {
      login: 'Login',
      email: 'Email',
      password: 'Password',
      forgotPassword: 'Forgot Password',
      customerLoginTitle: 'Customer Login',
      loginWithGoogle: 'Login with Google',
      incorrectCredentials: 'Email or password is incorrect',
      loginFailed: 'Could not log in: {{error}}',
    },
    userNotFound: {
      title: 'User Not Found',
      description: 'Could not log in, an error occurred, or the user no longer exists.',
      goToLoginPage: 'Go to Login Page',
    },
  },
  upload: {
    title: 'Upload',
    uploadDocuments: 'Upload documents',
    confirmDeleteDocument: 'Are you sure you want to delete this document?',
    deleteDocument: 'Delete document',
    noDocumentsFound: 'No documents found',
    noDocumentsSelected: 'No documents selected',
    uploadFailed: 'Could not upload document',
    uploadDocumentFailed: 'Could not upload file {{filename}}: {{reason}}',
    multiUploadFailed: 'Could not upload files: {{reason}}',
  },
  or: 'or',
  cancel: 'Cancel',
  close: 'Close',
  moreInfo: 'More info',
  previous: 'Previous',
  next: 'Next',
  delete: 'Delete',
};
